/* eslint-disable @next/next/no-img-element */
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useRouter } from 'next/router'
import { ENTITY, SHARE_DATA_TYPES, WEBSITE } from 'src/consts'
import { copyToClipboard } from 'src/utils/copyToClipboard'
import ButtonGroup, { ButtonGroupItem } from 'src/components/ButtonGroup'
import NotOkIcon from 'public/assets/icons/icon-notok.svg'
import { combinedAnalytics } from 'src/utils/common'
import Dialog, { DialogProps } from 'src/components/Dialog'
import InnerLoader from 'src/components/InnerLoader'
import Social from '../Social'
import styles from './styles.module.scss'
import { useShareDialog } from './useShareDialog'

export type ShareDialogProps = {
  onClose: () => void
  open: boolean
  noImage?: boolean
}

type Share = 'widget' | 'screenshot'

export const ShareDialog = ({ noImage, open, onClose }: ShareDialogProps) => {
  const router = useRouter()
  const location = router.asPath

  const shareType = ['/exchange', '/watchlist', '/', '/nft'].includes(
    router.pathname
  )
    ? SHARE_DATA_TYPES.MAP
    : SHARE_DATA_TYPES.LINK

  let entity
  if (shareType === SHARE_DATA_TYPES.MAP) {
    if (['/watchlist', '/'].includes(location)) entity = ENTITY.COIN
    if (location === '/exchange') entity = ENTITY.EXCHANGE
    if (location === '/nft') entity = ENTITY.NFT
  }

  const mapData = useShareDialog({
    entity,
    shareType,
    location,
  })

  const { loading } = mapData

  const data = useMemo(
    () => ({
      description: mapData.shareData.description,
      linkImg: mapData.linkImg,
      linkShare: mapData.linkShare,
      shareType,
      title: mapData.shareData.title,
    }),
    [mapData, shareType]
  )

  const inputValue =
    data.shareType === SHARE_DATA_TYPES.LINK
      ? `${WEBSITE}${location}`
      : data.linkShare
  const [share, setShare] = useState<Share>('screenshot')

  useEffect(() => {
    if (data.shareType === SHARE_DATA_TYPES.MAP) {
      combinedAnalytics('Share map', 'Share map', 'Open share map', '-')
    } else {
      combinedAnalytics('Share card', 'Share card', 'Open share card', '-')
    }
  }, [data.shareType])

  const getTitle = useMemo(() => {
    const { shareType, title } = data
    return (
      {
        [SHARE_DATA_TYPES.SYMBOL]: 'Share card',
        [SHARE_DATA_TYPES.MAP]: 'Share Market State',
      }[shareType] || title
    )
  }, [data])

  const handleCopy = () => {
    try {
      const success = copyToClipboard(inputValue)

      if (success) {
        combinedAnalytics(
          'Share map',
          'Share map',
          'Interaction',
          `Copy:${inputValue}`
        )
        toast('Link has been successfully copied!')
      }
    } catch (e) {
      toast('Failed to copy the link!', {
        icon: <NotOkIcon height='20' width='20' />,
      })
    }
  }

  const handleShareInteraction = (label, type = 'Click') => {
    const { shareType } = data
    combinedAnalytics(
      `Share ${shareType.toLowerCase()}`,
      `Share ${shareType.toLowerCase()}`,
      'Interaction',
      `${type} ${label}`
    )
  }

  const renderSocial = () => {
    if (share === 'widget') return null
    const { description, title, shareType, linkShare, linkImg } = data

    return (
      <Social
        description={description}
        linkImg={linkImg}
        linkShare={linkShare}
        shareType={shareType}
        title={title}
      />
    )
  }

  const renderWidget = () => {
    if (share === 'screenshot') return null

    const changedcolorsWidget = !window.location.search
      ? '?utm_source=embed_map'
      : window.location.search + '&utm_source=embed_map'

    return (
      <textarea
        className={styles.widgetTextarea}
        onCopy={() => handleShareInteraction('website', 'Copy')}
        readOnly
        value={`<iframe src="${WEBSITE}/widget/map${changedcolorsWidget}" frameborder="0" title="Coin360.com: Cryptocurrency Market State" width="400" height="300"></iframe>`}
      />
    )
  }

  const renderScreenshot = () => {
    if (noImage) return null
    return (
      <img
        alt=''
        className={styles.image}
        src={data.linkImg}
        onClick={() => handleShareInteraction('screenshot')}
        width='100%'
      />
    )
  }

  const hasWidget = data.shareType === SHARE_DATA_TYPES.MAP

  const dialogProps: DialogProps = {
    onClose,
    open,
    size: 'small',
    title: getTitle,
    children: null,
  }

  if (loading) {
    return (
      <Dialog {...dialogProps}>
        <div style={{ position: 'relative', height: 300, width: 300 }}>
          <InnerLoader />
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog {...dialogProps}>
      <div className={styles.root}>
        {hasWidget && (
          <ButtonGroup
            className={styles.share}
            onChange={setShare}
            value={share}
          >
            <ButtonGroupItem value='screenshot'>Screenshot</ButtonGroupItem>
            {entity !== ENTITY.NFT && (
              <ButtonGroupItem value='widget'>Website widget</ButtonGroupItem>
            )}
          </ButtonGroup>
        )}
        {renderScreenshot()}
        {share === 'screenshot' && (
          <div className={styles.linkBox}>
            <input
              className={styles.input}
              onCopy={() => handleShareInteraction('link', 'Copy')}
              value={inputValue}
            />
            <div className={styles.copy} onClick={() => handleCopy()} />
          </div>
        )}
        {renderSocial()}
        {hasWidget && renderWidget()}
      </div>
    </Dialog>
  )
}
